import { useContext } from "react";
import { usePathname } from "next/navigation";
import { pathToRegexp } from "path-to-regexp";
import {
    Bed,
    Boat,
    Books,
    CalendarBlank,
    ChalkboardTeacher,
    ChatsTeardrop,
    CheckSquareOffset,
    ClipboardText,
    Files,
    GlobeStand,
    Handshake,
    House,
    ImageSquare,
    ListMagnifyingGlass,
    Megaphone,
    PencilLine,
    PenNib,
    PhoneIncoming,
    PiggyBank,
    ShieldStar,
    SquaresFour,
    SuitcaseRolling,
    UserRectangle,
    Users,
    Wrench
} from "@phosphor-icons/react";
import {
    URI_BASE,
    URI_BRAND_ASSETS,
    URI_CLIENTS,
    URI_COMMISSIONS,
    URI_EVENTS,
    URI_FORA_PROFILE,
    URI_FORUM_SHORTCUT,
    URI_GET_STARTED,
    URI_HOME,
    URI_LEADS,
    URI_ONBOARDING,
    URI_PARTNERS,
    URI_PARTNERS_SUPPLIERS,
    URI_PAYMENTS,
    URI_POLICIES,
    URI_RESOURCES,
    URI_SHAREABLE_ASSETS,
    URI_TRAINING_V2
} from "constants/urls";
import { BookingPlatformViewModes } from "lib/types/supplier-database.types";
import { StoreContext } from "store";
import { useAuth } from "hooks/use-auth";
import { useGetStartedEnabled } from "hooks/useGetStartedEnabled";
import { NavItem } from "./NavTabTray";

/** Returns true if the given url matches that of the window */
export const isLinkActive = (url: string, pathname: string): boolean =>
    pathToRegexp(url.replace(/\?.+/, ""), [], { sensitive: true, end: pathname === URI_HOME }).test(
        pathname
    );

/** Sets the active flag for a nav item if it hasn't been set yet */
const setIsActive = (item: NavItem, pathname: string): void => {
    if (item.active === undefined && item.url) {
        item.active = isLinkActive(item.url, pathname);
    }
};

const GET_STARTED_ITEM: NavItem = {
    label: "Get started",
    Icon: CheckSquareOffset,
    url: URI_GET_STARTED
};

type SubNavGroup = {
    label: string;
    items: NavItem[];
};

export interface NavTab extends NavItem {
    subNavGroups?: SubNavGroup[];
}

/** Returns a list of tabs, each with their own sub-links, representing the pages of Advisor Portal */
export const useNavTabs = (): NavTab[] => {
    const { isFeatureEnabled } = useAuth();
    const pathname = usePathname();

    const { getStartedHidden } = useGetStartedEnabled();

    const showPaymentsPage = isFeatureEnabled("payments_page", "show_on_navtab");
    const showEvents = isFeatureEnabled("calendar_events");
    const showLeadsPage = isFeatureEnabled("leads_program");

    const { bookingFiltersURI } = useContext(StoreContext);
    const currentPath = window.location.pathname;
    const suppliersUrl = `${URI_PARTNERS}?view_mode=${BookingPlatformViewModes.LIST}&supplierType=hotels&clearAll=true`;
    const bookingsUrl = bookingFiltersURI || URI_COMMISSIONS;

    const navTabs: NavTab[] = [
        {
            label: "Home",
            Icon: House,
            url: URI_BASE,
            subNavGroups: [
                {
                    label: "Welcome",
                    items: [
                        {
                            ...GET_STARTED_ITEM,
                            hidden: getStartedHidden,
                            active:
                                (!getStartedHidden && currentPath === URI_BASE) ||
                                currentPath.includes(URI_GET_STARTED)
                        },
                        {
                            label: "Dashboard",
                            Icon: SquaresFour,
                            url: URI_HOME,
                            active:
                                (getStartedHidden && currentPath === URI_BASE) ||
                                currentPath === URI_HOME ||
                                currentPath.includes(URI_ONBOARDING)
                        }
                    ]
                },
                {
                    label: "Community",
                    items: [
                        {
                            label: "Events",
                            Icon: CalendarBlank,
                            url: URI_EVENTS,
                            hidden: !showEvents
                        },
                        { label: "Forum", Icon: ChatsTeardrop, url: URI_FORUM_SHORTCUT },
                        { label: "Policies", Icon: ClipboardText, url: URI_POLICIES }
                    ]
                }
            ]
        },
        {
            label: "Book",
            Icon: Bed,
            url: suppliersUrl,
            subNavGroups: [
                {
                    label: "Book travel",
                    items: [
                        {
                            label: "Partners",
                            Icon: Bed,
                            url: suppliersUrl,
                            active: isLinkActive(URI_PARTNERS_SUPPLIERS, pathname)
                        }
                    ]
                }
            ]
        },
        {
            label: "Manage",
            Icon: PencilLine,
            url: bookingsUrl,
            subNavGroups: [
                {
                    label: "My business",
                    items: [
                        { label: "Bookings", Icon: SuitcaseRolling, url: bookingsUrl },
                        { label: "Clients", Icon: Users, url: URI_CLIENTS },
                        {
                            label: "Payments",
                            Icon: PiggyBank,
                            url: URI_PAYMENTS,
                            hidden: !showPaymentsPage
                        }
                    ]
                },
                {
                    label: "Opportunities",
                    items: [
                        {
                            label: "Leads",
                            Icon: PhoneIncoming,
                            url: URI_LEADS,
                            hidden: !showLeadsPage
                        }
                    ]
                },
                {
                    label: "Client development",
                    items: [
                        { label: "Fora profile", Icon: UserRectangle, url: URI_FORA_PROFILE },
                        { label: "Shareable assets", Icon: PenNib, url: URI_SHAREABLE_ASSETS },
                        { label: "Brand assets", Icon: ImageSquare, url: URI_BRAND_ASSETS },
                        { label: "Templates & forms", Icon: Files, url: URI_RESOURCES }
                    ]
                }
            ]
        },
        {
            label: "Learn",
            Icon: Books,
            url: URI_TRAINING_V2,
            subNavGroups: [
                {
                    label: "Courses",
                    items: [
                        { label: "Certified", Icon: ChalkboardTeacher /*, url: TODO */ },
                        { label: "Advanced", Icon: ShieldStar /*, url: TODO */ },
                        { label: "Cruise", Icon: Boat /*, url: TODO */ }
                    ]
                },
                {
                    label: "Training library",
                    items: [
                        { label: "All trainings", Icon: ListMagnifyingGlass, url: URI_TRAINING_V2 },
                        { label: "Destinations", Icon: GlobeStand /*, url: TODO */ },
                        { label: "Partners", Icon: Handshake /*, url: TODO */ },
                        { label: "Find clients", Icon: Megaphone /*, url: TODO */ },
                        { label: "Marketing toolkit", Icon: Wrench /*, url: TODO */ },
                        {
                            ...GET_STARTED_ITEM,
                            hidden: !getStartedHidden,
                            active: getStartedHidden && currentPath.includes(URI_GET_STARTED)
                        }
                    ]
                }
            ]
        }
    ];
    // Set the active flag for all sub-nav items that don't have special cases for it
    navTabs.forEach(tab =>
        tab.subNavGroups?.forEach(({ items }) =>
            items.forEach(item => {
                setIsActive(item, pathname);
                // If the sub-nav item is active, set the entire tab to active
                if (item.active) tab.active = true;
            })
        )
    );

    return navTabs;
};
