import { useEffect, useState } from "react";
import { Info } from "@phosphor-icons/react";
import { twMerge } from "tailwind-merge";
import { Button } from "../button/Button";
import { NavItem, NavTabTray } from "./NavTabTray";
import { NavTab } from "./useNavTabs";
import { useModalManager } from "hooks/use-modal-manager";
import { trackReportIssueClicked } from "analytics/issues/report-issue-analytics";
import { SubNavLink, subNavLinkStyles } from "./nav-links";

/** Returns true if a nav item should be shown */
const navItemVisible = (item: NavItem): boolean => !item.hidden && !!item.url;

/** Returns the default active tab. If on mobile and no tab is active, default to the first one. */
const getDefaultTab = (tabs: NavTab[], mobile?: boolean): NavTab | undefined =>
    tabs.find(tab => tab.active) ?? (mobile ? tabs[0] : undefined);

type TabSubNavProps = {
    /** The list of page navigation tabs (entire sidebar will be hidden on desktop unless a tab is marked active) */
    tabs: NavTab[];
    /** If true, displays a tab tray of the primary */
    mobile?: boolean;
    isOpen?: boolean;
    hideNavbar?: () => void;
};

/** A left-side sub-nav of grouped page links. In desktop view, only the current tab's links can be viewed.
 * In mobile view, tabs are shown to the left of the links and can be changed to see all pages. */
export const TabSubNav = ({
    tabs,
    mobile,
    isOpen,
    hideNavbar
}: TabSubNavProps): JSX.Element | null => {
    const [activeTab, setActiveTab] = useState(() => getDefaultTab(tabs, mobile));
    const visibleGroups = activeTab?.subNavGroups?.filter(({ items }) =>
        items.some(navItemVisible)
    );

    useEffect(() => {
        if (isOpen) return;
        const defaultTab = getDefaultTab(tabs, mobile);
        if (activeTab !== defaultTab) {
            // Reset the tab after the sidebar closes
            setTimeout(() => setActiveTab(defaultTab), 500);
        }
    }, [isOpen]);

    const { openModal } = useModalManager();

    // If there are no visible sub items, don't render the side nav
    if (!activeTab || !visibleGroups) return null;

    const onClickReportIssue = () => {
        hideNavbar?.();
        openModal("report-issue");
        trackReportIssueClicked();
    };

    const subNav = (
        <div
            className={twMerge(
                "flex-col justify-between",
                mobile
                    ? "flex desktop:hidden w-[264px] p-6 bg-surface-1 rounded-xl"
                    : "hidden desktop:flex min-w-[228px] p-4 bg-[#FDFBF9]"
            )}
        >
            <div className="flex flex-col gap-y-[22px]">
                <h2 className="desktop:hidden text-headerFS24 font-normal">{activeTab.label}</h2>
                <div className="flex flex-col gap-y-6">
                    {visibleGroups.map(group => (
                        <div
                            key={group.label}
                            className="flex flex-col gap-y-2 desktop:gap-y-4 pb-6 border-primary [&:not(:last-child)]:border-b"
                        >
                            <span className="text-smallLH20 text-secondary font-normal">
                                {group.label}
                            </span>
                            <div className="flex flex-col gap-y-1">
                                {group.items.map(
                                    item =>
                                        navItemVisible(item) && (
                                            <SubNavLink
                                                key={item.label}
                                                href={item.url ?? ""}
                                                Icon={item.Icon}
                                                active={item.active}
                                            >
                                                {item.label}
                                            </SubNavLink>
                                        )
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <button className={subNavLinkStyles()} onClick={onClickReportIssue}>
                <Info size={18} />
                <span>Report an issue</span>
            </button>
        </div>
    );

    return !mobile ? (
        subNav
    ) : (
        <nav className="h-full w-min flex bg-brand rounded-r-xl relative">
            <NavTabTray tabs={tabs} activeTab={activeTab} onClickTab={setActiveTab} mobile />
            {subNav}
        </nav>
    );
};
