import React, { useState } from "react";
import Head from "next/head";
import { twMerge } from "tailwind-merge";
import Aside from "components/Aside";
import { APP_PAGE_TITLE_POSTFIX } from "constants/index";
import { useThemeContext } from "../../../../store";
import { useNavTabs } from "./useNavTabs";
import TabHeader from "./TabHeader";
import { ImpersonatedUserBar } from "../ImpersonatedUserBar";
import { TabSubNav } from "./TabSubNav";

const CARD_SHADOW = "desktop:shadow-[0_0_8px_rgba(0,0,0,0.05)]";

type TabLayoutProps = { children?: React.ReactNode; title?: string; className?: string };

/** A tabbed navigation experience */
export const TabLayout = ({ children, title, className }: TabLayoutProps) => {
    const { mainContainerRef } = useThemeContext();
    const [navbarOpen, setNavbarOpen] = useState(false);
    const tabs = useNavTabs();

    return (
        <>
            <Head>
                <title>{title ? `${title} - ${APP_PAGE_TITLE_POSTFIX}` : ""}</title>
            </Head>
            <div className="h-dvh flex flex-col desktop:px-2 desktop:pb-2 bg-brand">
                <TabHeader tabs={tabs} showNavbar={() => !navbarOpen && setNavbarOpen(true)} />
                <ImpersonatedUserBar />
                <Aside
                    isOpen={navbarOpen}
                    hideNavbar={() => navbarOpen && setNavbarOpen(false)}
                    tabs={tabs}
                />
                <div
                    className={`size-full flex overflow-hidden desktop:rounded-xl bg-surface-1 ${CARD_SHADOW}`}
                >
                    <TabSubNav tabs={tabs} />
                    <div className="min-w-0 flex-grow pt-2 [&:not(:first-child)]:shadow-[-4px_0_12px_0_rgba(0,0,0,0.04)]">
                        <div
                            ref={mainContainerRef}
                            className="flex justify-center tablet:px-8 h-full overflow-auto"
                        >
                            <div
                                className={twMerge(
                                    `flex flex-col w-full max-w-[1120px] min-h-min h-full`,
                                    className
                                )}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
