import { useRouter } from "next/router";
import React from "react";
import { useSession } from "next-auth/react";
import { twMerge } from "tailwind-merge";
import { URI_LOGIN } from "constants/urls";
import { SideNav } from "./common/v2/SideNav/SideNav";
import { TabSubNav } from "./common/v2/TabNav/TabSubNav";
import { NavTab } from "./common/v2/TabNav/useNavTabs";

const ASIDE_Z_INDEX = "z-30";

// Opt out from static page optimizations
export async function getServerSideProps() {
    return { props: {} };
}

type AsideProps = {
    isOpen: boolean;
    hideNavbar: () => void;
    // Used to make Aside compatible with LayoutV2. Once we've fully deprecated Layout in favor of LayoutV2, we should apply this props changes permanently and remove this prop
    layoutV2?: boolean;
    // If defined, uses Nav V3's tabbed sidebar
    tabs?: NavTab[];
};

/** The mobile side-nav for Portal (hidden on desktop, off-screen by default on mobile) */
export default function Aside({ isOpen, hideNavbar, layoutV2 = false, tabs }: AsideProps) {
    const router = useRouter();
    const { data: session } = useSession();
    const isImpersonating = session?.isImpersonating;

    return router.pathname !== URI_LOGIN ? (
        <>
            {tabs && (
                // Overlay behind expanded sidenav
                <div
                    className={twMerge(
                        "desktop:hidden fixed inset-0 bg-black/50 transition-opacity duration-300",
                        isOpen ? "opacity-100" : "opacity-0 !size-0",
                        ASIDE_Z_INDEX
                    )}
                    onClick={hideNavbar}
                />
            )}
            <aside
                id="side-menu"
                className={twMerge(
                    isImpersonating && !layoutV2 && !tabs && "lg:pt-[65px]",
                    layoutV2 ? "left-0 lg:static h-full" : "h-[100dvh]",
                    "fixed top-0 transition-transform duration-300 lg:transform-none lg:opacity-100 w-[340px] lg:w-64",
                    !isOpen && "-translate-x-[340px]",
                    tabs && "desktop:hidden",
                    ASIDE_Z_INDEX
                )}
            >
                {!tabs ? (
                    <SideNav isOpen={isOpen} hideNavbar={hideNavbar} />
                ) : (
                    <TabSubNav tabs={tabs} isOpen={isOpen} hideNavbar={hideNavbar} mobile />
                )}
            </aside>
        </>
    ) : null;
}
