import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { twMerge } from "tailwind-merge";
import { DotsThreeVertical, Envelope, List, Question, Robot } from "@phosphor-icons/react";
import {
    URI_CHATBOT,
    URI_FORA_EMAIL,
    URI_HELP_PORTAL,
    URI_LOGIN,
    URI_USER_PROFILE
} from "constants/urls";
import { ForaLogo } from "../ForaLogo";
import { isLinkActive, NavTab } from "./useNavTabs";
import { NavTabTray } from "./NavTabTray";
import { IconButton } from "../IconButton";
import { useAuth } from "hooks/use-auth";
import { CopyIATAButton, HeaderIconLink } from "./nav-links";
import { Button } from "../button/Button";
import { Modal } from "../modal/Modal";

type HeaderProps = {
    tabs: NavTab[];
    showNavbar: () => void;
};

export const FOCUS_OUTLINE =
    "focus-visible:!outline focus-visible:!outline-2 focus-visible:!outline-offset-2 focus-visible:!outline-link";

/** A horizontal nav bar at the top of each page, with a Fora logo, tabs, and buttons */
export default function TabHeader({ tabs, showNavbar }: HeaderProps): JSX.Element | null {
    const router = useRouter();
    const { userProfile } = useAuth();
    const [menuOpen, setMenuOpen] = useState(false);

    if (router.pathname === URI_LOGIN) return null;

    return (
        <header
            className={
                "grid grid-cols-2 desktop:grid-cols-[1fr_auto_1fr] items-center bg-brand py-2 pl-2 pr-6 desktop:px-4 desktop:py-0"
            }
        >
            <div className="flex gap-x-1 items-center">
                <IconButton
                    variant="text"
                    shape="square"
                    Icon={List}
                    className="desktop:hidden"
                    onClick={showNavbar}
                />
                <ForaLogo
                    className="h-5 desktop:h-6 min-w-[66px] cursor-pointer"
                    onClick={() => router.push("/")}
                />
            </div>
            <NavTabTray tabs={tabs} />
            <div className="flex gap-x-2 items-center justify-end">
                <Button
                    variant="text"
                    PhosphorIcon={DotsThreeVertical}
                    iconProps={{ weight: "bold" }}
                    className="desktop:hidden"
                    onClick={() => setMenuOpen(true)}
                />
                <Modal open={menuOpen} onClose={() => setMenuOpen(false)}>
                    <div className="flex flex-col gap-y-2 [&>*]:p-2 [&>*]:text-left">
                        <Link className="p-2" href={URI_HELP_PORTAL}>
                            Help Center
                        </Link>
                        <Link href={URI_CHATBOT}>Sidekick</Link>
                        <Link href={URI_FORA_EMAIL} target="_blank">
                            Fora Email
                        </Link>

                        <CopyIATAButton mobile />
                    </div>
                </Modal>
                <div className="hidden desktop:flex gap-x-2 items-center">
                    <CopyIATAButton />
                    <HeaderIconLink
                        Icon={Envelope}
                        tooltip="Fora email"
                        href={URI_FORA_EMAIL}
                        target="_blank"
                    />
                    <HeaderIconLink Icon={Robot} tooltip="Sidekick" href={URI_CHATBOT} />
                    <HeaderIconLink Icon={Question} tooltip="Help" href={URI_HELP_PORTAL} />
                </div>
                <Link
                    className="cursor-pointer [&:focus-visible>*]:outline min-w-10"
                    href={URI_USER_PROFILE}
                >
                    <img
                        src={userProfile?.photo || "/img/user-default.jpg"}
                        alt="Your Profile image"
                        width={48}
                        height={48}
                        className={twMerge(
                            "rounded-full border-transparent border-4 outline-black",
                            !isLinkActive(URI_USER_PROFILE, router.pathname)
                                ? "hover:border-[#63574a33] outline-1 -outline-offset-1"
                                : "border-[#63574a33] outline outline-2 -outline-offset-2"
                        )}
                        data-testid="profile-image"
                    />
                </Link>
            </div>
        </header>
    );
}
